/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs
} from "react-google-maps";

const Markers = ({ places }) => {
  return places.map(place => {
    return (
      <Marker label={place.num} key={place.id} position={{ lat: place.lat, lng: place.lng }} />
    );
  });
};

const Map = ({ places, zoom, center }) => {
  return (
    <GoogleMap defaultZoom={zoom} center={center}>
      <Markers places={places} />
    </GoogleMap>
  );
};

class MapWithMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { places: this.props.places, center: this.props.center }; //initialize initial state from props
  }

  handleMarkerClick = (place) => {
    console.log(place)
  }

  render() {
    return (
      <Map
        center={this.props.center}
        zoom={this.props.zoom}
        places={this.props.places}
      />
    );
  }
}

export default withScriptjs(withGoogleMap(MapWithMarker));
