import logo from './logo.svg';
import React from 'react';
import DOM from 'react-dom';
import './App.css';

import Map from "./Map";

import AsyncSelect from 'react-select/async';
const axios = require('axios');

const host = 'https://parks.kun.org.uk';
const mapsApiKey = 'AIzaSyDnqGRv_4LYZOZfuhRcA_rgMq0U5ZIeZGU';

type State = {
  inputValue: string,
  mapsApiKey: string,
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      if (inputValue.length < 3) {
        resolve([])
        return
      }
      if (inputValue.length >= 3) {
        console.log(inputValue.length)
      axios.get(host+'/search', {params: {"search": inputValue}})
        .then((resp) => {
          if (resp.data.postcodes.length < 1) {
            return
          }
          if (resp.data.postcodes.map instanceof Function) {
            const data = resp.data.postcodes.map((item) => {
              return {"value": item.replace(/\s/g, ''), "label": item}
            })
            resolve(data)
          }
        })
      }
    }, 500);
  });

class App extends React.Component<*, State> {
  state = { inputValue: '', selected: [], places: [], mapsApiKey: mapsApiKey, center: {x: 51.5074, y: -0.1 }};
  handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };
  handleChange = (selectedOptions) => {
    if (selectedOptions == null || selectedOptions.length < 1) {
      this.setState({selected: []})
      return
    }
    let data = selectedOptions.map((item) => {
      return item.value
    })
    this.setState({selected: data});
  };
  handleClick = () => {
    axios.post(host+'/parks',{
      "postcodes": this.state.selected
    }).then((resp) => {
      this.setState({places: resp.data.map((item, i) => {
        this.setState({center: {x: item.latitude, y: item.longitude}})
        return {"id": item.id, "name": item.name, "lat": item.latitude, "lng": item.longitude, "num": (i + 1).toString()}
      })})
    })
  }

  render() {
    return (
      <div>
        <div>Postcodes:</div>
        <AsyncSelect
          isMulti
          cacheOptions
          defaultOptions
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          loadOptions={promiseOptions}
        />
        <button disabled={this.state.selected.length < 1} onClick={this.handleClick}>
          Search
        </button>

      <Map
        center={{ lat: this.state.center.x, lng: this.state.center.y }}
        zoom={11}
        googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key="+this.state.mapsApiKey}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        places={this.state.places}
      />
      <div>
        {this.state.places.map(place => (
          <p>{place.num}: {place.name}</p>
        ))}
      </div>
      </div>
    );
  }
}

DOM.render(<App/>, document.getElementById('root'))
